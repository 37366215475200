/* eslint-disable jsx-a11y/control-has-associated-label */

/* eslint-disable react/no-danger */

/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable react/jsx-props-no-spreading */
import { useUser } from '@folklore/auth';
import { useForm } from '@folklore/forms';
import classNames from 'classnames';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useContestParticipate } from '../../hooks/useContest';
import * as AppPropTypes from '../../lib/PropTypes';
import { getComponentFromName } from '@folklore/utils';

import RoundedButton from '../buttons/RoundedButton';
import CheckboxField from '../fields/CheckboxField';
import Date from '../fields/DateField';
import ContactField from '../fields/ContactField';
import TextField from '../fields/TextField';
import * as FieldComponents from '../fields/index';
import FormDescription from '../typography/FormDescription';
import FormControl from './FormControl';
import FormRow from './FormRow';

import styles from '../../styles/forms/contest-form.module.css';

const propTypes = {
    contest: AppPropTypes.contest.isRequired,
    source: PropTypes.string,
    withEmail: PropTypes.bool,
    withCancel: PropTypes.bool,
    onComplete: PropTypes.func,
    onCancel: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    source: null,
    withEmail: false,
    withCancel: false,
    onComplete: null,
    onCancel: null,
    className: null,
};

function DefaultContest({
    source,
    contest,
    withEmail,
    withCancel,
    onComplete: customOnComplete,
    onCancel,
    className,
}) {
    const intl = useIntl();
    const {
        id: contestId,
        handle: contestHandle,
        description = null,
        instructions = null,
        success = null,
        rules = null,
        emailOnly = false,
        optins = null,
        extraFields = null,
    } = contest;
    const { url: rulesUrl = null } = rules || {};

    const user = useUser();
    const formFields = useMemo(
        () =>
            [
                withEmail && 'email',
                'firstname',
                'lastname',
                'birthdate',
                'postalcode',
                ...(optins || []).map(({ id }) => `optin_${id}`),
                ...(extraFields || []).map(({ name }) => `extra_${name}`),
            ].filter(Boolean),
        [withEmail, optins, extraFields],
    );
    const { contests = [] } = user || {};
    const [completed, setCompleted] = useState(
        (contests || []).findIndex(({ id }) => id === contestHandle) !== -1,
    );

    const [type, setType] = useState(null);

    const onComplete = useCallback(
        (newUser) => {
            setCompleted(true);
            if (customOnComplete !== null) {
                customOnComplete(newUser);
            }
        },
        [customOnComplete],
    );

    const initialValue = useMemo(
        () => (user !== null ? pick(user, formFields) : null),
        [user, formFields],
    );

    const { participateAsync } = useContestParticipate();

    const postForm = useCallback(
        (postAction, postData) => {
            const { email, ...other } = postData || {};
            const isSms = type === 'tel';
            return participateAsync({
                id: contestId,
                ...other,
                ...(isSms ? { phone: email } : { email }),
                source,
            });
        },
        [type, user, source, participateAsync, contestId],
    );

    const { fields, onSubmit, status, setValue, value, errors } = useForm({
        fields: formFields,
        initialValue,
        onComplete,
        postForm,
    });

    useEffect(() => {
        if (value === null && user !== null) {
            setValue(pick(user, formFields));
            const { contests: userContests = [] } = user;
            setCompleted((userContests || []).findIndex(({ id }) => id === contestHandle) !== -1);
        }
    }, [value, user, formFields]);

    const onInputTypeChange = useCallback(
        (newType) => {
            setType(newType);
        },
        [setType],
    );

    return (
        <form
            method="post"
            className={classNames([
                styles.container,
                {
                    'was-validated': errors !== null,
                },
                className,
            ])}
            onSubmit={onSubmit}
        >
            {completed ? (
                <div className={styles.success}>
                    {success || (
                        <FormattedMessage defaultMessage="Vous êtes inscrit·e au concours!" />
                    )}
                </div>
            ) : (
                <>
                    {description !== null ? (
                        <FormDescription html={description} className={styles.description} />
                    ) : null}
                </>
            )}

            {completed && instructions !== null ? (
                <div
                    className={styles.instructions}
                    dangerouslySetInnerHTML={{ __html: instructions }}
                />
            ) : null}

            {!completed ? (
                <div className={styles.fields}>
                    {withEmail ? (
                        <FormRow className={styles.formRow}>
                            <FormControl
                                label={
                                    emailOnly ? (
                                        <FormattedMessage
                                            defaultMessage="Courriel"
                                            description="Field label"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="Téléphone ou courriel"
                                            description="Field label"
                                        />
                                    )
                                }
                                className={classNames([styles.formControl])}
                                labelClassName={styles.label}
                            >
                                <ContactField
                                    {...fields.email}
                                    withBorder
                                    type={emailOnly ? 'email' : null}
                                    placeholder={
                                        emailOnly
                                            ? intl.formatMessage({
                                                  defaultMessage: 'Courriel',
                                                  description: 'Field placeholder',
                                              })
                                            : intl.formatMessage({
                                                  defaultMessage: 'Téléphone ou courriel',
                                                  description: 'Field placeholder',
                                              })
                                    }
                                    className={styles.field}
                                    onTypeChange={onInputTypeChange}
                                    required
                                />
                            </FormControl>
                        </FormRow>
                    ) : null}

                    <FormRow className={styles.formRow}>
                        <FormControl
                            label={
                                <FormattedMessage
                                    defaultMessage="Prénom"
                                    description="Field label"
                                />
                            }
                            {...fields.firstname}
                            withoutErrors
                            className={classNames([styles.formControl, styles.firstName])}
                            labelClassName={styles.label}
                        >
                            <TextField
                                {...fields.firstname}
                                withBorder
                                placeholder="Prénom"
                                className={styles.field}
                            />
                        </FormControl>
                        <FormControl
                            label={
                                <FormattedMessage defaultMessage="Nom" description="Field label" />
                            }
                            {...fields.lastname}
                            withoutErrors
                            className={classNames([styles.formControl, styles.lastName])}
                            labelClassName={styles.label}
                        >
                            <TextField
                                {...fields.lastname}
                                withBorder
                                placeholder="Nom"
                                className={styles.field}
                            />
                        </FormControl>
                    </FormRow>

                    <FormRow alwaysHorizontal className={styles.formRow}>
                        <FormControl
                            label={
                                <FormattedMessage
                                    defaultMessage="Date de naissance"
                                    description="Field label"
                                />
                            }
                            {...fields.birthdate}
                            withoutErrors
                            className={classNames([styles.formControl, styles.birthDate])}
                            labelClassName={styles.label}
                        >
                            <Date
                                {...fields.birthdate}
                                withBorder
                                // placeholder="Date de naissance"
                                className={styles.field}
                                placeholderClassName={styles.datePlaceholder}
                            />
                        </FormControl>
                        <FormControl
                            {...fields.postalcode}
                            withoutErrors
                            label={
                                <FormattedMessage
                                    defaultMessage="Code postal"
                                    description="Field label"
                                />
                            }
                            className={classNames([styles.formControl, styles.postalCode])}
                            labelClassName={styles.label}
                        >
                            <TextField
                                {...fields.postalcode}
                                withBorder
                                placeholder="Code postal"
                                className={styles.field}
                            />
                        </FormControl>
                    </FormRow>

                    {extraFields !== null
                        ? extraFields.map(({ type: fieldType, name, label, ...field }) => {
                              const FieldComponent = getComponentFromName(
                                  FieldComponents,
                                  fieldType,
                              );
                              return (
                                  <FormControl
                                      label={label}
                                      {...fields[`extra_${name}`]}
                                      withoutErrors
                                      className={classNames([styles.formControl, styles.formRow])}
                                      labelClassName={styles.label}
                                  >
                                      <FieldComponent
                                          {...fields[`extra_${name}`]}
                                          {...field}
                                          withBorder
                                          className={styles.field}
                                      />
                                  </FormControl>
                              );
                          })
                        : null}

                    {optins !== null && optins.length > 0 ? (
                        <div className={styles.optins}>
                            {(optins || []).map(({ id, label }) => (
                                <FormControl
                                    {...fields[`optin_${id}`]}
                                    className={classNames([styles.formControl, styles.formRow])}
                                    withoutErrors
                                >
                                    <CheckboxField
                                        name={`optin_${id}`}
                                        label={label}
                                        withBorder
                                        {...fields[`optin_${id}`]}
                                    />
                                </FormControl>
                            ))}
                        </div>
                    ) : null}

                    <div
                        className={classNames([
                            styles.actions,
                            {
                                [styles.withCancel]: withCancel,
                            },
                        ])}
                    >
                        <RoundedButton
                            type="submit"
                            dark
                            className={styles.submitButton}
                            disabled={status === 'loading'}
                            iconClassName={styles.icon}
                        >
                            {status === 'loading' ? (
                                <FormattedMessage
                                    defaultMessage="Enregistrement..."
                                    description="Button label"
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Participer"
                                    description="Button label"
                                />
                            )}
                        </RoundedButton>

                        {withCancel && status !== 'loading' && user === null ? (
                            <RoundedButton
                                type="button"
                                dark
                                className={styles.link}
                                onClick={onCancel}
                            >
                                <FormattedMessage
                                    defaultMessage="Non merci!"
                                    description="Button label"
                                />
                            </RoundedButton>
                        ) : null}

                        {rulesUrl !== null ? (
                            <div className={classNames([styles.rules, styles.link])}>
                                <a href={rulesUrl} target="_blank" rel="noopener noreferrer">
                                    <small>
                                        <FormattedMessage
                                            defaultMessage="Voir les règlements"
                                            description="Contest label"
                                        />
                                    </small>
                                </a>
                            </div>
                        ) : null}
                    </div>

                    {instructions !== null ? (
                        <div
                            className={styles.instructions}
                            dangerouslySetInnerHTML={{ __html: instructions }}
                        />
                    ) : null}
                </div>
            ) : null}
        </form>
    );
}

DefaultContest.propTypes = propTypes;
DefaultContest.defaultProps = defaultProps;

export default DefaultContest;
