/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import {
    defaultCountries,
    usePhoneInput,
    CountrySelector,
    DialCodePreview,
} from 'react-international-phone';

import useEmailPhoneAutocomplete from '../../hooks/useEmailPhoneAutocomplete';
import * as AppPropTypes from '../../lib/PropTypes';

import PhoneField from './PhoneField';
import TextField from './TextField';

import styles from '../../styles/fields/contact-field.module.css';
import 'react-international-phone/style.css';

const propTypes = {
    type: PropTypes.string,
    defaultType: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    withoutAutocomplete: PropTypes.bool,
    onTypeChange: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: AppPropTypes.ref,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
};

const defaultProps = {
    type: null,
    defaultType: 'email',
    value: null,
    placeholder: null,
    required: false,
    withoutAutocomplete: false,
    onTypeChange: null,
    onChange: null,
    onFocus: null,
    onBlur: null,
    inputRef: null,
    className: null,
    inputClassName: null,
};

function ContactField({
    type,
    value: initialValue,
    defaultType,
    placeholder,
    required,
    withoutAutocomplete,
    onTypeChange,
    onChange,
    onFocus,
    onBlur,
    inputRef,
    className,
    inputClassName,
    ...props
}) {
    const onAutocompleteChange = useCallback(
        (newValue, fieldType) => {
            if (fieldType !== 'tel' && onChange !== null) {
                const finalValue = newValue || null;
                onChange(finalValue);
            }
        },
        [onChange],
    );

    const {
        ref: autoCompleteRef,
        value,
        suggestion = null,
        placeholder: customPlaceholder,
        onChange: onInputChange,
        setValue,
        setType,
        type: fieldType = null,
    } = useEmailPhoneAutocomplete({
        value: initialValue,
        autocomplete: !withoutAutocomplete,
        onChange: onAutocompleteChange,
        onTypeChange,
        type,
        defaultType,
    });

    const onPhoneChange = useCallback(
        (newValue) => {
            if (fieldType === 'tel' && onChange !== null) {
                onChange(newValue.inputValue.length > 0 ? newValue.phone : null);
            }
        },
        [fieldType, onChange, setType],
    );

    const {
        inputValue,
        handlePhoneValueChange,
        inputRef: phoneRef,
        country,
        setCountry,
    } = usePhoneInput({
        defaultCountry: 'ca',
        value: value || '',
        countries: defaultCountries,
        disableDialCodePrefill: true,
        disableDialCodeAndPrefix: true,
        onChange: onPhoneChange,
    });

    useEffect(() => {
        if (initialValue === null) {
            setValue(initialValue);
        }
    }, [initialValue]);

    const finalPlaceholder =
        !withoutAutocomplete && !isEmpty(value) ? suggestion || customPlaceholder : placeholder;

    const onFieldChange = useCallback(
        (newValue, e) => {
            onInputChange(newValue);
            if (fieldType === 'tel') {
                handlePhoneValueChange(e);
            }
        },
        [fieldType, onInputChange, handlePhoneValueChange],
    );

    return (
        <div className={classNames([styles.container, styles[fieldType]])}>
            {fieldType === 'tel' ? (
                <div className={styles.phoneSelector}>
                    <CountrySelector
                        selectedCountry={country.iso2}
                        onSelect={({ iso2 }) => setCountry(iso2)}
                        className={styles.country}
                    />
                    <DialCodePreview
                        dialCode={country.dialCode}
                        prefix="+"
                        className={styles.dialCode}
                    />
                </div>
            ) : null}
            <TextField
                ref={(newRef) => {
                    autoCompleteRef.current = newRef;
                    if (fieldType === 'tel') {
                        phoneRef.current = newRef;
                    } else {
                        phoneRef.current = null;
                    }

                    if (inputRef !== null) {
                        inputRef.current = newRef;
                    }
                }}
                type={fieldType}
                required={required}
                placeholder={finalPlaceholder}
                value={fieldType === 'tel' ? inputValue : value}
                autocomplete={fieldType}
                withBorder
                className={classNames([styles.input, className])}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onFieldChange}
            />
        </div>
    );
}

ContactField.propTypes = propTypes;
ContactField.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <ContactField {...props} inputRef={ref} />);
